<template>
    <div class="basic_data_edit xmdy_map">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">项目调研</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <div class="mapPart">
                <Mymap ref="myMap" :myMap.sync="myMap" :mapData="mapData">
                </Mymap>
                <!-- 图例 -->
                <div class="legendPart flex">
                    <div class="title">图例</div>
                    <div class="flex flex-1 list">
                        <div
                            v-for="(item, index) in legendList"
                            :key="index"
                            class="flex-y-center legendItem"
                        >
                            <img :src="item.src" class="img" />
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <div class="flex-between">
                    <div class="formLeft">
                        <el-form-item
                            prop="XMMC"
                            label="项目名称"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.XMMC"
                                :disabled="!isEdit"
                                :maxlength="100"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="SM"
                            label="现场情况描述"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.SM"
                                resize="none"
                                type="textarea"
                                :disabled="!isEdit"
                                :autosize="{ minRows: 2, maxRows: 6 }"
                                :maxlength="500"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="位置"
                            class="from-item-block"
                            prop="WZ"
                        >
                            <el-input
                                v-model="formdata.dataDic.WZ"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="formRight">
                        <el-form-item
                            prop="CJSJ"
                            label="调研时间"
                            class="from-item-block"
                        >
                            <el-date-picker
                                v-model="formdata.dataDic.CJSJ"
                                placeholder="选择调研时间"
                                :disabled="!isEdit"
                            ></el-date-picker>
                        </el-form-item>

                        <el-form-item
                            prop="DYRMC"
                            label="调研人"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.DYRMC"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="村寨"
                            prop=""
                            class="from-item-block"
                        >
                            <el-select
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.YCZC"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(item, index) in yczcList"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item label="现场照片" class="from-item-block" prop="">
                    <div class="flex flex-wrap">
                        <audio
                            v-for="(item, index) in mp3List"
                            :key="index"
                            style="margin-right: 10px"
                            :src="item.path"
                            controls
                        ></audio>
                    </div>
                    <div class="flex flex-wrap" style="margin-top: 10px">
                        <el-image
                            v-viewer
                            v-for="(item, index) in imgList"
                            :key="index"
                            :src="item.path"
                            fit="cover"
                            class="pointer"
                            style="
                                width: 150px;
                                height: 150px;
                                margin-right: 10px;
                            "
                        >
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData typeNum="1" width="100px"></SnNoData>
                            </div>
                        </el-image>
                    </div>
                    <div class="flex flex-wrap">
                        <video
                            v-for="(item, index) in mp4List"
                            :key="index"
                            :src="item.path"
                            controls
                            style="
                                width: 150px;
                                height: 150px;
                                margin-right: 10px;
                            "
                        ></video>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            mapData: {},
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "90401",
                PIC: [],
                dataDic: {
                    YCZC: "",
                    XMMC: "",
                    WZ: "",
                    SHZT: 0,
                    SM: "",
                    WD: "",
                    CJSJ: "",
                    DYRMC: "",
                    ID: "",
                    JD: "",
                    RKSJ: "",
                },
            },
            yczcList: [], // 遗产组成
            rules: {},
            myMap: null,
            markerGroup: null,
            markerPopup: null,
            mp3List: [],
            mp4List: [],
            imgList: [],
            //图例
            legendList: [
                {
                    name: "未审核",
                    src: require("@image/working_desk/monitor_data/xmdy/wsh.png"),
                },
                {
                    name: "未提交",
                    src: require("@image/working_desk/monitor_data/xmdy/wtj.png"),
                },
                {
                    name: "已通过",
                    src: require("@image/working_desk/monitor_data/xmdy/ytg.png"),
                },
                {
                    name: "未通过",
                    src: require("@image/working_desk/monitor_data/xmdy/wtg.png"),
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getYczcList();
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -8],
            className: "markerPopup",
        });
        this.markerGroup = window.L.layerGroup().addTo(this.myMap);
    },
    methods: {
        ...mapActions(["GetYczcListByYcys"]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        GetDataNext() {
            console.log(
                "%c 🍅 this.formdata: ",
                "font-size:20px;background-color: #7F2B82;color:#fff;",
                this.formdata
            );

            this.mp3List = [];
            this.mp4List = [];
            this.imgList = [];
            this.formdata.PIC.map((item) => {
                let dw = item.path.substr(item.path.lastIndexOf(".") + 1);
                switch (dw) {
                    case "mp3":
                        this.mp3List.push(item);
                        break;
                    case "mp4":
                        this.mp4List.push(item);
                        break;
                    default:
                        this.imgList.push(item);
                        break;
                }
            });
            this.myMap.flyTo(
                [this.formdata.dataDic.WD, this.formdata.dataDic.JD],
                13
            );
            this.drawMarkerList(this.markerGroup, [this.formdata.dataDic]);
        },
        drawMarkerList(group, list) {
            this.clearLayersGroup(group);
            // SHZT: "0", // 未审核
            // SHZT: "1", // 未提交
            // SHZT: "2", // 已通过
            // SHZT: "3", // 未通过
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:30px;height:30px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${
                        this.legendList[item.SHZT].src
                    } />`,
                    iconSize: [30, 30],
                    iconAnchor: [15, 15],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {});
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.XMMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 获取遗产组成列表
        async getYczcList() {
            let result = await this.GetYczcListByYcys({
                ycysbm: "",
                ycysfl: "传统村落",
            });
            let list = [];
            result.RESULTVALUE.map((item) => {
                list.push(item.YCYSFL_2);
            });
            this.yczcList = Array.from(new Set(list));
        },
    },
};
</script>

<style scoped lang="scss">
.mapPart {
    width: 100%;
    height: 400px;
    position: relative;
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        height: 56px;
        z-index: 1000;
        .title {
            width: 20px;
            height: 56px;
            line-height: 23px;
            background: #186d70;
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            background: #fff;
            .legendItem {
                margin-right: 10px;
                .img {
                    margin-right: 5px;
                    width: 24px;
                    height: 24px;
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    color: #666;
                }
            }
        }
    }
}
.formLeft {
    width: 50%;
}

.formRight {
    width: 50%;
}
</style>
